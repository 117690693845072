DO.Subscribe('app:ready', function(e, $) {

	"use strict";

	function init() {
		setup($(document));
	}


	function setup(scope) {

		attachEvents(scope);

		if(scope.find('[data-pagination]').length < 1) {
			return;
		}

		var pagination = $('[data-pagination]');

		pagination.ssendless({
			contentSelector: '[data-paginationcontent]',
			templates: {
				main: '<div class="ss-pagination"><a href="#" data-page-number="<%= nextPage %>" class="js-pagination-title pure-button pure-button--primary article-landing-pagination-button">Show more</a></div>'
			}
		});
	}

	function attachEvents(scope) {

		if ($('[data-pagination]').length > 0) {
			var pagination = $('[data-pagination]');

			pagination.on('ssendlessbeforepagefetch', function (event) {
				$(this).siblings('.ss-pagination').addClass('load').find('a').html('<span class="text">Loading</span>');
			});

			pagination.on('ssendlessafterpagefetch', function (event) {
				$(this).siblings('.ss-pagination').removeClass('load').find('a').html('<span class="text">Show more</span>');
			});
		}


		if (scope.is(document)) {
			$(document).on('ajaxpages:articlesfiltered', function (e, data) {
				setup(data.scope);
			});
		}
	}

	init();


});



DO.Subscribe('app:ready', function (e, $) {

    "use strict";

    function init() {
        closeAccordions();
        bindEvent();
    }

    function bindEvent() {
        $('.accordion-action').on('click', function (e) {
            e.preventDefault();
            if ($(e.target).hasClass('open')) {
                var item = $(e.target).closest('.accordion-item').find('.item-full');
                openAccordion(item);
            }
            else {
                closeAccordions();
            }
        });
    }

    function openAccordion(item) {
        var isOpen = item.hasClass('active'),
            summary = item.closest('.accordion-item').find('.item-summary');

        if (isOpen) {
            closeAccordions();
        }
        else{
            closeAccordions(function(){
                summary.hide();
                item.show();
                item.animate({height: item.data('height')}, 200);
                item.addClass('active');
            });
        }
    }

    function closeAccordions(callback) {
        $('.accordion .item-full').each(function () {
            var item = $(this),
                itemHeight = item.height(),
                summary = item.closest('.accordion-item').find('.item-summary'),
                summaryHeight = summary.height();

            if (!item.data('height')) {
                item.data('height', itemHeight);
            }

            item.animate({height: summaryHeight}, {
                duration: 200,
                complete: function () {
                    item.hide();
                    summary.show();
                    item.removeClass('active');

                    if(callback){
                        callback();
                    }
                }
            });
        });
    }

    init();
});
DO.Subscribe('app:ready', function (e, $) {

    "use strict";

    function init() {
        syncHeight();

        $(window).on('resize', function(){
            syncHeight();
        });
    }

    function syncHeight(){
        $('.ElementBusinessBenefit .row').each(function () {
            var columns = $(this).find('.column');

            if($(window).width() > 1167) {
                var heights = columns.map(function () {
                    return $(this).height();
                }).get();

                var maxHeight = Math.max.apply(null, heights);

                columns.each(function(){
                    $(this).height(maxHeight);
                });
            }
            else{
                columns.each(function(){
                    $(this).css('height','auto');
                });
            }
        });
    }

    init();
});
DO.Subscribe('app:ready', function (e, $) {

    "use strict";

    function init() {
        $('.video-module video').click(function () {
            var playButton = $(this).closest('.video-module').find('.play');
            if($(this).get(0).paused){
                this.play();
                playButton.hide();
            }
            else{
                this.pause();
                playButton.show();
            }
        });

        $('.video-module .play').click(function (e) {
            e.preventDefault();
             $(e.target).closest('.video-module').find('video').trigger('click');
        });
    }

    init();
});
DO.Subscribe('app:ready', function(e, $) {

	$('.gmap').each(function(i, el) {

		if(typeof google === "undefined") return;

		var lat = $(this).data('lat');
		var lng = $(this).data('lng');

		var map;
		var mapOptions = {
			center: new google.maps.LatLng(lat,lng),
			zoom: 17,
			zoomControl: false,
			zoomControlOptions: {
			  style: google.maps.ZoomControlStyle.DEFAULT
			},
			disableDoubleClickZoom: true,
			mapTypeControl: false,
			mapTypeControlOptions: {
			  style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR
			},
			scaleControl: true,
			scrollwheel: false,
			panControl: false,
			streetViewControl: false,
			draggable: true,
			overviewMapControl: false,
			overviewMapControlOptions: {
				opened: false
			},
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			styles:[
				    {
				        "featureType": "administrative",
				        "elementType": "labels",
				        "stylers": [
				            {
				                "visibility": "on"
				            },
				            {
				                "gamma": "1.82"
				            }
				        ]
				    },
				    {
				        "featureType": "administrative",
				        "elementType": "labels.text.fill",
				        "stylers": [
				            {
				                "visibility": "on"
				            },
				            {
				                "gamma": "1.96"
				            },
				            {
				                "lightness": "-9"
				            }
				        ]
				    },
				    {
				        "featureType": "administrative",
				        "elementType": "labels.text.stroke",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "landscape",
				        "elementType": "all",
				        "stylers": [
				            {
				                "visibility": "on"
				            },
				            {
				                "lightness": "25"
				            },
				            {
				                "gamma": "1.00"
				            },
				            {
				                "saturation": "-100"
				            }
				        ]
				    },
				    {
				        "featureType": "poi.business",
				        "elementType": "all",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "poi.park",
				        "elementType": "all",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "road",
				        "elementType": "geometry.stroke",
				        "stylers": [
				            {
				                "visibility": "off"
				            },
					        {
						        "saturation": "-100"
					        }
				        ]
				    },
				    {
				        "featureType": "road",
				        "elementType": "labels.icon",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "road.highway",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "saturation": "-100"
				            },
				            {
				                "visibility": "on"
				            }
				        ]
				    },
				    {
				        "featureType": "road.highway",
				        "elementType": "geometry.stroke",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "road.highway",
				        "elementType": "labels",
				        "stylers": [
				            {
				                "visibility": "simplified"
				            },
				            {
				                "saturation": "-100"
				            }
				        ]
				    },
				    {
				        "featureType": "road.highway.controlled_access",
				        "elementType": "labels",
				        "stylers": [
				            {
				                "visibility": "on"
				            }
				        ]
				    },
				    {
				        "featureType": "road.arterial",
				        "elementType": "all",
				        "stylers": [
				            {
				                "visibility": "on"
				            },
				            {
				                "saturation": "-100"
				            },
				            {
				                "lightness": "30"
				            }
				        ]
				    },
				    {
				        "featureType": "road.local",
				        "elementType": "all",
				        "stylers": [
				            {
				                "saturation": "-100"
				            },
				            {
				                "lightness": "40"
				            },
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "transit.station.airport",
				        "elementType": "geometry.fill",
				        "stylers": [
				            {
				                "visibility": "on"
				            },
				            {
				                "gamma": "0.80"
				            }
				        ]
				    },
				    {
				        "featureType": "water",
				        "elementType": "all",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    }]
		}

		var icon = {
			url: '/themes/default/images/icons/google-pin.png',
			size: new google.maps.Size(33,45),
			origin: new google.maps.Point(0,0),
			scaledSize: new google.maps.Size(33,45)
		};

		var mapElement = document.getElementById('map');
		var map = new google.maps.Map(mapElement, mapOptions);

		var marker = new google.maps.Marker({
			position: new google.maps.LatLng(lat, lng),
			icon: icon,
			map: map
		});

		var addressOne = $(this).attr('data-addressOne'),
			addressTwo = $(this).attr('data-addressTwo'),
			addressThree = $(this).attr('data-addressThree'),
			addressFour = $(this).attr('data-addressFour');

		if(addressOne === undefined) {
			addressOne = '';
		}

		if(addressTwo === undefined) {
			addressTwo = '';
		}

		if(addressThree === undefined) {
			addressThree = '';
		}

		if(addressFour === undefined) {
			addressFour = '';
		}

		var contentStr = '<div id="content" class="pure-u">'+
			'<div id="bodyContent">'+
			'<p style="margin: 0;">'+addressOne+'</p>' +
			'<p style="margin: 0;">'+addressTwo+'</p>' +
			'<p style="margin: 0;">'+addressThree+'</p>' +
			'<p style="margin: 0;">'+addressFour+'</p>' +
			'</div>'+
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentStr
		});

		marker.addListener('click', function() {
			infowindow.open(map, marker);
		})

	});
});

DO.Subscribe('app:ready', function(e, $) {
	"use strict";

	$('.hamburger-menu-toggle').on('click', function() {
		$('.menu--mobile').addClass('open');
		$('body').addClass('mobile-menu-open');

		setTimeout(function() {
			$('.hamburger-menu-toggle--mob').children('.con').addClass('active');
			$('.hamburger-menu-toggle--mob').addClass('active');
			$('.menu--mobile .menu-item').fadeIn('slow');
		}, 500)
	});

	$('.hamburger-menu-toggle--mob').on('click', function() {
		$('.menu--mobile').removeClass('open');
		$('body').removeClass('mobile-menu-open');
		$('.hamburger-menu-toggle--mob').children('.con').removeClass('active');
		$('.hamburger-menu-toggle--mob').removeClass('active');
		$('.menu--mobile .menu-item').fadeOut();
	});

	$(window).resize(function(){
		var winSize = $(window).width() + 15;

		if(winSize > 767) {
			$('.menu--mobile').removeClass('open');
			$('body').removeClass('mobile-menu-open');
			$('.hamburger-menu-toggle').removeClass('active');
		}
	});
});



DO.Subscribe('app:ready', function(e, $) {

	// Animation in on page loads. Currently this will load elements on the homepage.
	// This is likely to be used on all Element Pages
	window.sr = ScrollReveal();

	sr.reveal(
		'.scroll-reveal',
		{
			scale: 1,
			viewFactor: 0.15,
			duration: 1000
		}
	);

	$('html').addClass('page-loaded');

	$(window).on('beforeunload',function() {
		$('html').removeClass('page-loaded');
	});
});

// SITEMAP
DO.Subscribe('app:ready', function(e, $) {
	"use strict";


	function init() {
		attachEvents();
	}

	function attachEvents() {
		$('.sitemap').on('click', '.button', function() {
			var self = $(this),
				target = $(self.attr('data-target'));

			// only do an ajax request if the content isn't loaded
			if(target.html().length === 0) {
				requestAjax(target, self);
			}

			toggleChildren(target, self);

			return false;
		});
	}

	function requestAjax(target, self) {
		self.addClass('loading');

		$.ajax({
			url: self.attr('href'),
			data: { ajax: true }
		}).done(function(data) {
			target.html(data);
			self.removeClass('loading');
		});
	}


	function toggleChildren(target, self) {
		self.toggleClass('open');

		if(self.hasClass('open')) {
			target.removeClass('collapse').addClass('collapsed');
			self.attr('aria-expanded', 'true');
			self.children('.linkText').replaceWith('<span class="linkText">Collapse section</span>');
		} else {
			target.removeClass('collapsed').addClass('collapse');
			self.attr('aria-expanded', 'false');
			self.children('.linkText').replaceWith('<span class="linkText">Expand section</span>');
		}
	}

	init();
});

/**
* Slick slider init
*
*/
DO.Subscribe('app:ready', function(e, $) {

	"use strict";

	function init() {
		setup($(document));
	}


	function setup(scope) {
		if(scope.find('[data-slider]').length < 1) {
			return;
		}

		scope.find('[data-slider]').slick(getConfig());

		attachEvents(scope);
	}

	function attachEvents(scope) {
		if(scope.is(document)) {
			$(document).on('ajaxpages:contentloaded', function(e, data) {
				setup(data.target);
			});
		}
	}

	//[0, 568, 768, 1024, 1280]
	function getConfig() {
		return {
			mobileFirst: true,
			variableWidth: true,
			infinite: false,
			slidesToShow: 1,
			arrows: true,
			prevArrow: '<button type="button" class="pure-button pure-button-icononly slick-prev"><span class="sr-only">Previous</span></button>',
			nextArrow: '<button type="button" class="pure-button pure-button-icononly slick-next"><span class="sr-only">Next</span></button>',
			responsive: [{
				breakpoint: 1280,
				settings: {
					slidesToShow: 4,
				}
			},{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
				}
			}, {
				breakpoint: 600,
				settings: {
					slidesToShow: 3
				}
			}, {
				breakpoint: 480,
				settings: {
					arrows: true,
					slidesToShow: 2
				}
			}]
		};
	}

	init();

});


DO.Subscribe('app:ready', function(e, $) {
	"use strict";

	$('.article-share-icon').on('click', function() {
		$('.article-social-icons').fadeToggle().parent('.article-icons-wrapper').toggleClass('open');
	});

	$('.social-icon:not(.email)').each(function(){
		var self = $(this);
		console.log(self);

		self.attr('onclick', "window.open('"+self.data('link')+"', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');");
		self.removeAttr('data-link');
	});

	$('.social-icon:not(.email)').click(function(e){
		return false;
	});
});


/**
 * Toggles and switches extra js (for accessibility). 
 * Uses ../lib/toggles-switches.js from https://github.com/dsurgeons/Toggles-Switches
 */

 DO.Subscribe('app:ready', function(e, $) {

	"use strict";

	var focusable = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]',
		focusableNative = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed';

	/**
	 * Initialize Button actions
	 */
	function init() {
		setup($(document));
	}

	/**
	 * Apply setup to our newly loaded area (document, or scope)
	 *
	 * @param scope | DOMObject - the area of the page we are applying this functionality to
	 */
	function setup(scope) {
		// if aria hidden, we shouldn't be able to focus
		scope.find('[aria-hidden="true"]').find(focusable).attr('tabindex', '-1');

		attachEvents(scope);
	}


	/**
	 * If an element has the attribute data-toggle-aria we want to toggle the
	 * aria-hidden attribute of the data-toggle-aria value and the tabindex of
	 * all focusable children.
	 * @param scope | DOMObject
	 */
	function attachAriaHide(scope) {
		var targets = scope.find("[data-toggle-aria]"),
			i;

		// Set aria hidden and tabindex -1
		targets.on('click', function() {
			var target = $(this).data('toggle-aria'),
				value = $(target).attr('aria-hidden') == "false" ? "true" : "false",
				tabindex = value == "false" ? "0" : "-1";

			$(target).attr('aria-hidden', value);
			$(target).find(focusable).attr('tabindex', tabindex);
		});
	}

	/**
	 * Attach events
	 * @param scope | DOMObject
	 */
	function attachEvents(scope) {
		attachAriaHide(scope);

		scope.find('[data-contain]').on('click', function(e) {
			e.stopPropagation();
		});

		//make sure we get the order of our events right
		setTimeout(function(){
			keyboardFocus(scope);
		}, 1000);

		if(scope.is(document)) {

			$(document).on('ajaxpages:contentloaded', function(e, data) {
				setup(data.target);
			});
		}
	}

	/**
	 * Allow a focus state that only appears for people using keyboards to navigate
	 * @param scope | DOMObject
	 */
	function keyboardFocus(scope) {
		scope.find(focusable).on('keyup',function(e) {
			$(e.target).addClass("hasfocus");

			if($(e.target).is(focusableNative)){
				return;
			}

			// trigger click if this element doesn't usually support click
			if(e.keyCode == 13) {
				e.preventDefault();
				e.stopPropagation();
				$(e.target).trigger('click');
			}

		}).on('blur focusout',function(e) {
			$(e.target).removeClass("hasfocus");
		});
	}

	init();

});


/**
 * Youtube API & cover image handling
 */

var onYouTubeIframeAPIReady = function() {
	DO.Fire('app:bindvideo', []);
};

// Load the IFrame Player API code asynchronously.
var tag = document.createElement('script');
tag.src = "//www.youtube.com/player_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

DO.Subscribe(['app:ready', 'ajax:success', 'app:bindvideo'], function (e, $) {

	function init () {
		setupVideo();
		attachEvents();
		attachHtml5Video();
	};

	function setupVideo() {
		$('.video-iframe--youtube').each(function() {
			setupYoutube(this);
		});
	};

	function attachEvents() {
		$('.video-cover').on('click', function(e) {
			e.preventDefault();

			var player,
				iframe = $($(this).data('target'));

			if(!iframe.length) return;

			player = iframe.data('player');
			if(player) {
				$(this).remove();
				player.playVideo();
			}
		});
	};

	function setupYoutube(el) {
		if(typeof YT !== "undefined" && typeof $(el).data('player') == "undefined") {
			player = new YT.Player($(el).attr('id'), {
				events: {
					onStateChange: function(event) {
						var player = event.target,
							iframe = $(player.getIframe());
						switch (event.data) {
							case 1:
							//trackVideo(iframe, 1);
							//break;
							case 2: // paused
								//trackVideo(iframe, 2, player.getCurrentTime(), player.getDuration());
								break;
							case 0:	// ended
							//trackVideo(iframe, 3); break;
							default:
								// track nothing on unstarted (-1), buffering(3) and cued (5)
								break;
						}
					},
					onReady: function(event) {
						console.log('READY');

						// auto play video
						if($(el).attr('autoplay')) {
							$(el).siblings('.video-cover').remove();
							event.target.playVideo();
						}
						return;
					}
				}
			});

			$(el).data('player', player);
		}
	};

	/**
	 * handle intialising html5 video on desktop only
	 * @return {[type]} [description]
	 */
	function attachHtml5Video() {
		$('video').each(function() {
			var self = this,
				video = $(this).get(0),
				winSize = $(window).width() + 15;

			if(winSize < 767) {
				$(this).next('img').show();

			} else {
				$(this).find('source').each(function() {
					$(this).attr('src', $(this).attr('data-src'));
				});

				video.load();
				video.addEventListener('loadeddata', function() {
					if(!self.hasClass('nopreload')){
                        video.play();
					}
				});

				$(this).next('img').hide();
			}
		});
	}


	init();
});
